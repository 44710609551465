<template>
	<div class="reset-password">
		<el-form :model="passwordFormData" status-icon :rules="passwordFormRule" ref="ruleForm" label-width="100px" class="demo-ruleForm">
			<el-form-item label="账号" prop="account">
				<el-input v-model.number="passwordFormData.account"></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="password">
				<el-input type="password" v-model="passwordFormData.password" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="confirmPassword">
				<el-input type="password" v-model="passwordFormData.confirmPassword" autocomplete="off"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')" :loading="changeLoading">确认修改</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import {changePassword} from "@/api/login"

export default {
	name: "UserManage",
	data() {
		return {
			passwordFormData: {
				account: this.$store.state.account, password: "", confirmPassword: ""
			},
			passwordFormRule: {
				password: [
					{
						validator: (rule, value, callback) => {
							if (value === '') {
								callback(new Error('请输入密码'));
							} else {
								if (this.passwordFormData.confirmPassword !== '') {
									this.$refs.ruleForm.validateField('confirmPassword');
								}
								callback();
							}
						}, trigger: 'blur'
					}
				],
				confirmPassword: [
					{
						validator: (rule, value, callback) => {
							if (value === '') {
								callback(new Error('请再次输入密码'));
							} else if (value !== this.passwordFormData.password) {
								callback(new Error('两次输入密码不一致!'));
							} else {
								callback();
							}
						}, trigger: 'blur'
					}
				],
			},
			changeLoading: false
		}
	},
	methods: {
		submitForm() {
			this.changeLoading = true;
			changePassword(this.passwordFormData).then(response => {
				this.changeLoading = false;
				if (!response['success']) return this.$message.error('修改密码失败：' + response.data);
				this.$message.success('修改密码成功');
			}).catch((error) => {
				this.changeLoading = false;
				this.$message.error('修改密码失败：' + error);
			});
		}
	}
}
</script>

<style scoped>
.reset-password {
	width: 500px;
	margin: 20px auto;
}
</style>